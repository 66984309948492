/*eslint-disable*/
import React from "react"
import {useEffect, useState} from "react"
import ParallaxSection from "components/ParallaxSection"
// nodejs library that concatenates classes
import classNames from "classnames"
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart"
// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"
import Accordion from "components/Accordion/Accordion.js"
import LinearProgress from '@material-ui/core/LinearProgress';
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import {Link} from "gatsby"
import Typography from "@material-ui/core/Typography"



import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js"

import { gql, useQuery } from "@apollo/client"


import PageLayout from "../components/PageLayout"

const useStyles = makeStyles(productStyle)

const ListingPreviewPage = () => {

  const [data, setData] = useState({"gallery":[]})

  const classes = useStyles()

  useEffect(() => {
    window.addEventListener('message',function(event) {
      // console.log('message received:  ',event);
      // console.log('entry: ',event.data.entry);
      setData(event.data.entry)
    },true);

  },[data])

  console.log(data)


  const cmsURL = "https://cloud.completeconstruction.gr/"


  const images = data.gallery.map(image => ({
    original: cmsURL + image.path,
    thumbnail: cmsURL + image.path,
  }))


  return (
    <div className={classes.productPage}>
      <PageLayout>
        <ParallaxSection
          //   image="https://images.unsplash.com/photo-1429497419816-9ca5cfb4571a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80"
          image={
            data.image
              ? cmsURL+"storage/uploads"+data.image.path
              : images[0]?images[0].original:""
          }
          filter="dark"
          className={classes.pageHeader}
        />

        <div className={classNames(classes.section, classes.sectionGray)}>
          <div className={classes.container}>
            <div className={classNames(classes.main, classes.mainRaised)}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom:"2rem"}}>
                <Link color="inherit" to="/">
                  Αρχική
                </Link>
                <Link color="inherit" to="/listings">
                  Έργα
                </Link>
                <Typography color="textPrimary">Προεπισκόπιση</Typography>
              </Breadcrumbs>
              <GridContainer>
              <h2 className={classes.title}>
                    Προεπισκόπιση
                  </h2>
              <GridItem>
                {/* <GridItem md={6} sm={6}> */}
                  <ImageGallery
                    showNav={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    startIndex={3}
                    items={images}
                  />
                </GridItem>
                {/* <GridItem md={6} sm={6}>
                  <h2 className={classes.title}>
                    {data.title}
                  </h2>
                  <h3 className={classes.mainPrice}>Subtitle</h3>
                  <Accordion
                    active={0}
                    activeColor="rose"
                    collapses={[
                      {
                        title: "Description",
                        content: <p>{data.content}</p>,
                      },
                      {
                        title: "Designer Information",
                        content: (
                          <p>
                            An infusion of West Coast cool and New York
                            attitude, Rebecca Minkoff is synonymous with It girl
                            style. Minkoff burst on the fashion scene with her
                            best-selling {"'"}Morning After Bag{"'"} and later
                            expanded her offering with the Rebecca Minkoff
                            Collection - a range of luxe city staples with a{" "}
                            {'"'}
                            downtown romantic{'"'} theme.
                          </p>
                        ),
                      },
                      {
                        title: "Details and Care",
                        content: (
                          <ul>
                            <li>
                              Storm and midnight-blue stretch cotton-blend
                            </li>
                            <li>
                              Notch lapels, functioning buttoned cuffs, two
                              front flap pockets, single vent, internal pocket
                            </li>
                            <li>Two button fastening</li>
                            <li>84% cotton, 14% nylon, 2% elastane</li>
                            <li>Dry clean</li>
                          </ul>
                        ),
                      },
                    ]}
                  />
                </GridItem> */}
              </GridContainer>
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  )

}

export default ListingPreviewPage
